























































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Draggable from 'vuedraggable'
import controller from '@/app/ui/controllers/FAQController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LeaveModal from '@/app/ui/components/LeaveModal/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import QnAItem from '../components/QnAItem/index.vue'
import QnAItemSub from '../components/QnAItemSub/index.vue'
import CategoryImage from '../components/CategoryImage/index.vue'
import ModalDeleteQuestion from '../components/Modals/DeleteQuestion.vue'
import ModalDeleteSubCategory from '../components/Modals/DeleteSubCategory.vue'
import ModalWithSubCategory from '../components/Modals/WithSubCategory.vue'
import ModalNoSubCategory from '../components/Modals/NoSubCategory.vue'
import ModalConfirmationUpdate from '../components/Modals/ConfirmationUpdate.vue'
import { FAQ, QnA, QnASubCategory } from '@/domain/entities/FAQ'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { NavigationGuardNext, Route } from 'vue-router'

@Component({
  mixins: [validationMixin],
  components: {
    Draggable,
    TextInput,
    CategoryImage,
    ModalDeleteQuestion,
    ModalDeleteSubCategory,
    ModalWithSubCategory,
    ModalNoSubCategory,
    ModalConfirmationUpdate,
    RadioButton,
    LoadingOverlay,
    Modal,
    LeaveModal,
    QnAItem,
    QnAItemSub,
    AddIcon,
    DragIcon,
    CaretDownIcon,
    Toggle,
    Button,
    EmptyState,
  },
})
export default class EditFAQPage extends Vue {
  controller = controller
  successModal = false
  withSubCategoryModal = false
  noSubCategoryModal = false
  deleteQuestionModal = {
    show: false,
    order: 1,
    question: new QnA(),
    index: 0
  }
  deleteSubCategoryModal = {
    show: false,
    data: new QnASubCategory()
  }
  confirmationModal = false
  leavePromptModal = false

  nextNavigate = {} as NavigationGuardNext
  parameters = {
    tab: this.controller.tabOptions.PUBLISHED,
  }
  form = {
    categoryName: '',
    isActive: false,
    iconImage: '',
    withSubCategory: false,
    questions: [] as QnA[],
    subCategoryList: [] as QnASubCategory[]
  }
  successModalMsg = {
    title: '',
    body: '',
  }
  isEditing = false
  isSubmiting = false
  isValidate = false
  optionTabs = [
    {
      label: 'No Sub Category',
      value: 'no-sub-category',
      name: 'noSubCategory',
    },
    {
      label: 'With Sub Category',
      value: 'with-sub-category',
      name: 'withSubCategory',
    }
  ]
  currentTab = 'no-sub-category'

  beforeMount(): void {
    window.addEventListener('beforeunload', this.warnClosing)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.warnClosing)
    })
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void{
    if (this.isEditing && !this.isSubmiting) {
      this.nextNavigate = next
      this.leavePromptModal = true
      if (this.leavePromptModal) {
        return
      }
    }
    next()
  }

  created(): void {
    EventBus.$on(EventBusConstants.UPDATE_FAQ_SUCCESS, () => {
      this.successModalMsg = {
        title: 'FAQ Saved',
        body: 'New information has been saved',
      }
      this.isSubmiting = false
      this.isEditing = false
      this.successModal = true
    })

    EventBus.$on(EventBusConstants.DELETE_FAQ_SUCCESS, () => {
      this.successModalMsg = {
        title: 'Deleted',
        body: 'Your answer successfuly deleted',
      }
      this.successModal = true
      this.reSortOrder()
    })

    EventBus.$on(EventBusConstants.DELETE_SUB_CATEGORY_SUCCESS, () => {
      this.successModalMsg = {
        title: 'Deleted',
        body: 'Your sub category successfuly deleted',
      }
      this.successModal = true
      this.reSortOrder()
    })

    this.fetchDetail()
  }

  @Validations()
  validations() {
    if (this.form.withSubCategory) {
      return {
        form: {
          categoryName: {
            required,
            maxLength: maxLength(this.controller.constants.maxCategoryName),
          },
          iconImage: {
            required
          },
          subCategoryList: {
            required,
            minLength: minLength(1),
            $each: {
              subCategoryName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(this.controller.constants.maxSubCategoryName)
              },
              data: {
                required,
                minLength: minLength(1),
                $each: {
                  question: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(this.controller.constants.maxQuestion),
                  },
                  answer: {
                    required,
                    minLength: minLength(3),
                    maxLength: (text: string) =>
                      this.textLength(text) <= this.controller.constants.maxAnswer,
                  },
                }
              }
            },
          },
        },
      }
    } else {
      return {
        form: {
          categoryName: {
            required,
            maxLength: maxLength(this.controller.constants.maxCategoryName),
          },
          iconImage: {
            required
          },
          questions: {
            required,
            minLength: minLength(1),
            $each: {
              question: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(this.controller.constants.maxQuestion),
              },
              answer: {
                required,
                minLength: minLength(3),
                maxLength: (text: string) =>
                  this.textLength(text) <= this.controller.constants.maxAnswer,
              },
            },
          },
        },
      }
    }
  }

  @Watch('controller.faqDetail')
  onQuestionDataChanged(val: FAQ): void {
    if (val.withSubCategory) {
      this.currentTab = 'with-sub-category'
    } else {
      this.currentTab = 'no-sub-category'
    }
    this.setFormFAQ()
  }

  @Watch('form', { deep: true })
  onFormChanged(): void {
    if (!this.controller.isLoading) {
      this.isEditing = true
    }
  }

  get publishedData() {
    if (this.form.withSubCategory) {
      return this.form.subCategoryList?.map(item => {
        const items = item.data?.filter(unit => !unit.isDraft)
        if (items && items.length > 0) {
          return { ...item, data: items }
        }
      }).filter(unit => unit)
    } else {
      return this.form.questions.filter((item) => !item.isDraft)
    }
  }

  get unpublishedData() {
    if (this.form.withSubCategory) {
      return this.form.subCategoryList?.map(item => {
        const items = item.data?.filter(unit => unit.isDraft)
        if (items && items.length > 0) {
          return { ...item, data: items }
        }
      }).filter(unit => unit)
    } else {
      return this.form.questions.filter((item) => item.isDraft)
    }
  }

  get numberOfQuestion(): number {
    if (this.form.withSubCategory) {
      let rows = [] as QnA[]
      this.form.subCategoryList?.forEach(item => {
        rows = rows.concat(item.data as QnA)
      })
      return rows.length
    } else {
      return this.form.questions.length
    }
  }

  private setFormFAQ() {
    const value = this.controller.faqDetail
    // Sort Order Question
    const questionsFAQ = value.questionsData?.map((item, idx) => {
      item.order = idx + 1
      return item
    })
    // Sort Order Sub Category & Question
    const subCategoryFAQ = value.subCategoryList?.map((item, index) => {
      item.order = index + 1
      item.data = item.data?.map((unit, idx) => {
        unit.order = idx + 1
        return unit
      })
      return item
    })
    this.form = {
      categoryName: value.categoryName as string,
      isActive: value.isActive as boolean,
      iconImage: value.iconImage as string,
      withSubCategory: value.withSubCategory as boolean,
      questions: questionsFAQ || [] as QnA[],
      subCategoryList: subCategoryFAQ || [] as QnASubCategory[]
    }
  }

  private numberOfPosted(isPosted: boolean): number {
    if (this.form.withSubCategory) {
      let rows = [] as QnA[]
      this.form.subCategoryList.forEach(item => {
        if (isPosted) {
          const items = item.data?.filter(unit => !unit.isDraft)
          if (items && items.length > 0) rows = rows.concat(items)
        } else {
          const items = item.data?.filter(unit => unit.isDraft)
          if (items && items.length > 0) rows = rows.concat(items)
        }
      })
      return rows.length || 0
    } else {
      if (isPosted) {
        return this.publishedData.length || 0
      } else {
        return this.unpublishedData.length || 0
      }
    }
  }

  private indexQuestion(order: number, fromQuestion?: boolean) {
    if (fromQuestion) {
      return this.form.questions.findIndex(i => i.order === order)
    } else {
      return this.form.subCategoryList.findIndex(i => i.order === order)
    }
  }

  private onChangeWithSubCategory() {
    this.withSubCategoryModal = false
    if (this.controller.faqDetail.withSubCategory) {
      this.setFormFAQ()
    } else {
      this.withSubCategoryModal = false
      this.form.withSubCategory = true
      this.form.subCategoryList = [
        {
          subCategoryName: '',
          order: 1,
          data: this.form.questions
        }
      ]
      this.form.questions = []
    }
  }

  private onChangeNoSubCategory() {
    this.noSubCategoryModal = false
    this.form.withSubCategory = false
    let rows = [] as QnA[]
    this.form.subCategoryList?.forEach(item => {
      if (item && item.data) {
        rows = rows.concat(item.data)
      }
    })
    const result = rows.map((item, idx) => {
      return { ...item, order: idx + 1 }
    })
    this.form.questions = result
    this.form.subCategoryList = []
  }

  private onChangeTab(tab: string) {
    if (tab === 'with-sub-category') {
      this.withSubCategoryModal = true
    } else if (tab === 'no-sub-category') {
      this.noSubCategoryModal = true
    }
  }

  private async onFileChange(file: File) {
    if (file) {
      const image = await controller.postImageCategory(file)
      this.form.iconImage = image as string
    } else {
      this.form.iconImage = ''
    }
  }

  private reSortOrder(isSubCategory?: boolean) {
    if (isSubCategory) {
      this.form.subCategoryList.forEach((item, index) => {
        item.data?.forEach((unit, idx) => {
          const question = this.form.subCategoryList[index].data
          if (unit.isDraft && question) {
            question.push(question.splice(idx, 1)[0])
          }
        })
      })
      this.form.subCategoryList = this.form.subCategoryList.map((item) => {
        const result = item.data?.map((unit, idx) => {
          return { ...unit, order: idx + 1 }
        })
        return { ...item, data: result }
      })
    } else {
      for (let i = 0; i < this.form.questions.length; i++) {
        if (this.form.questions[i].isDraft) {
          this.form.questions.push(this.form.questions.splice(i, 1)[0])
        }
      }
      this.form.questions = this.form.questions.map((item, idx) => {
        item.order = idx + 1
        return item
      })
    }
  }

  private onLeavePage() {
    if (this.nextNavigate) {
      this.leavePromptModal = false
      this.nextNavigate()
    }
  }

  private warnClosing(event: {
    preventDefault: () => void
    returnValue: string
  }) {
    if (!this.isEditing || this.isSubmiting) return
    event.preventDefault()
    event.returnValue = ''
  }

  private onChangeSubCategory(val: string, index: number) {
    this.form.subCategoryList[index].subCategoryName = val
  }

  private onAddQnA(index: number) {
    if (this.form.withSubCategory) {
      // With Sub Category
      const orderSubCategory = this.form.subCategoryList[index].data
      this.form.subCategoryList[index].data?.push({
        question: '',
        answer: '',
        order: orderSubCategory ? orderSubCategory.length + 1 : 1,
        isDraft: false,
        isStar: false
      })
      this.reSortOrder(true)
    } else {
      // No Sub Category
      this.form.questions.push(
        new QnA(undefined, '', '', this.form.questions.length + 1, false, false)
      )
      this.reSortOrder()
    }
  }

  private onAddSubQnA() {
    const indexLastItem = this.form.subCategoryList.length
    const orderSub = this.form.subCategoryList[indexLastItem - 1].order
    if (orderSub) {
      this.form.subCategoryList.push({
        subCategoryName: '',
        order: orderSub + 1,
        data: [
          {
            question: '',
            answer: '',
            order: 1,
            isStar: false,
            isDraft: false
          }
        ]
      })
    }
  }

  private onRemoveSubQnA() {
    this.deleteSubCategoryModal.show = false
    const { data } = this.deleteSubCategoryModal
    if (!data.id) {
      this.form.subCategoryList = this.form.subCategoryList
        .filter(item => item.order !== data.order)
    } else {
      this.controller.deleteSubCategory(data.id)
    }
  }

  private onRemoveQnA() {
    this.deleteQuestionModal.show = false
    const { order, question, index } = this.deleteQuestionModal
    if (this.currentTab === 'with-sub-category') {
      // Remove Question in With Sub Category
      if (!question.id) {
        // Remove Sub Category from FE
        this.form.subCategoryList = this.form.subCategoryList.map((item, idx) => {
          if (index === idx) {
            const items = item.data?.filter(unit => unit.order !== order).map((q) => {
              if (q.order && q.order > order) {
                q.order = q.order - 1
              }
              return q
            })
            return { ...item, data: items }
          } else {
            return item
          }
        })
      } else {
        // Remove Sub Category from DB
        this.controller.deleteFAQ({
          categoryId: this.$route.params.id,
          faqId: `${question.id}`,
        })
      }
    } else {
      // Remove Question in No Sub Category
      if (!question.id) {
        // Remove Question from FE
        this.form.questions = this.form.questions.filter(item => item.order !== order)
      } else {
        // Remove Question from DB
        this.controller.deleteFAQ({
          categoryId: this.$route.params.id,
          faqId: `${question.id}`,
        })
      }
    }
  }

  private onDragged() {
    this.reSortOrder(this.form.withSubCategory)
  }

  private onDraggedSubCategory() {
    this.form.subCategoryList = this.form.subCategoryList.map((item, idx) => {
      item.order = idx + 1
      return item
    })
  }

  private onToggleStatus($event: { target: { checked: boolean } }) {
    this.form.isActive = $event.target.checked
  }

  private onUpdateFAQ() {
    this.confirmationModal = false
    this.isValidate = true
    if (!this.$v.form.$invalid) {
      this.isSubmiting = true
      controller.updateFAQ({
        categoryId: this.$route.params.id,
        categoryName: this.form.categoryName,
        isActive: this.form.isActive,
        data: this.form.questions,
        withSubCategory: this.form.withSubCategory,
        subCategoryList: this.form.subCategoryList,
        iconImage: this.form.iconImage,
        isEdit: true, // to differentiate modal popup
      })
    } else {
      Vue.notify({
        title: 'Update FAQ Invalid',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private fetchDetail() {
    controller.getFAQDetail(this.$route.params.id)
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.fetchDetail()
  }

  private textLength(input: string) {
    return Utils.stripTags(input).length
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_FAQ_SUCCESS)
    EventBus.$off(EventBusConstants.DELETE_FAQ_SUCCESS)
    EventBus.$off(EventBusConstants.DELETE_SUB_CATEGORY_SUCCESS)
  }
}
