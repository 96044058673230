

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import QnAItem from '../QnAItem/index.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import DragDisabledIcon from '@/app/ui/assets/icon_drag_disabled.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import { QnA, QnASubCategory } from '@/domain/entities/FAQ'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  inheritAttrs: false,
  components: {
    Draggable,
    TextInput,
    QnAItem,
    AddIcon,
    DragIcon,
    DragDisabledIcon,
    TrashIcon,
    CaretDownIcon
  },
})

export default class QnAItemSub extends Vue {
  @Prop({ required: true }) private index!: number
  @Prop({ required: true }) private order!: number
  @Prop({ default: true }) private isDefaultOpen!: boolean
  @Prop({ default: false }) private isDeleteSubCategory!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: false }) private isValidate!: boolean
  @Prop({ default: false }) private subCategoryNameError!: boolean
  @Prop({ default: false }) private isQuestionError!: boolean
  @Prop({ default: '' }) private subCategoryName!: string
  @Prop({ default: [] }) private questionsError!: unknown
  @Prop({ default: 50 }) private maxSubCategoryCount!: number
  @Prop({ default: 0 }) private maxQuestion!: number
  @Prop({ default: 0 }) private maxAnswer!: number
  @Prop({ type: Object, default: {} }) private data!: QnASubCategory
  @Prop({ default: [] }) private questions!: QnA[]
  @Prop({ default: [] }) private valueQuestion!: QnA[]

  isExpand = this.isDefaultOpen

  private onToggleQuestion() {
    this.isExpand = !this.isExpand
  }

  private onChange() {
    this.$emit('drag-question', this.valueQuestion, this.index)
  }

  private onRemoveQuestion(order: number, question: QnA, index: number) {
    this.$emit('delete-qna', order, question, index)
  }

  private onRemoveSubCategory() {
    this.$emit('delete-sub-category', this.data)
  }

  private textLength(input: string) {
    return Utils.stripTags(input).length
  }

  private indexQuestion(order: number) {
    return this.valueQuestion.findIndex(i => i.order === order)
  }
}
